.loginPage {
  position: relative; /* Ensure proper stacking context */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:rgb(180, 178, 185);
}
/* 
.loginPage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the pseudo-element is behind other content *
  background-image: url(".../../../../../public/images/backgroundLogin.avif"); /* Add your background image URL *
  background-size: cover;
  background-position: center;
  background-color: linear-gradient(30deg,rgb(66, 66, 255),rgb(31, 25, 134)); /* Set the background color with transparency *
} */
.loginPage .form{
  width:90%;
  max-width:400px;
  min-height: 300px;
  border-radius:10px;
  background:white;
  box-shadow:1px 1px 30px 0px rgb(168, 168, 173);
  padding:50px 16px;
  margin:0 auto;
}
.loginPage .form button{
  width:100%;
  border-radius:30px;
}