.confirmation{
    width:600px;
margin:0 auto;
margin-top:10px;
    border-radius:10px;
    min-height:400px;
    box-shadow:1px 1px 10px 0px grey;
    background:white;
    padding:20px;
}

.confirmation header{
    padding:6px;
    background:rgb(35, 97, 59);
    text-align:center;
    font-weight:bolder;
    text-transform: uppercase;
    color:white;
    border-radius:4px;

}



.confirmationContainer{
    padding:15px;
    max-width:100vw;
    overflow:auto;
}
.confirmation .sectionItem{
    padding:16px;
    background:rgb(224, 233, 229);
    margin-top:10px;
    border-radius:4px;
}

.confirmation  .container{
    padding:2px;
    border:1px solid white;
    gap:20px;
}
.confirmation  li{
    padding:10px;
list-style-type:none;
font-weight:600;
}