body {
  margin: 0;
  font-family:Roboto,  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  
  --blue-color: #0da139; /* Dark Blue */
  --blue-color2:#0fb441; /* Blue */
  --blue-color3: #11ce4a; /* Light Blue */
  --color:#0da139;
  --color-opacity-1:#0eb340;
  --color-opacity-2:#07d445;
  --color-opacity-3:#0ed449;
  --color-opacity-4:#13e652;
  --white:rgba(236,236,236);
  --colorDark:#07832c;
}