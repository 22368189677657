
.dashboard{
    width:100%;
    margin:0 auto;
    min-height:80vh;
    border-radius:20px;
    box-shadow:1px 1px 10px 0px rgb(244, 244, 247);
    background:white;
    padding:18px;
}
.cardContainer{
    padding:17px 0px;
    margin:0 auto;
}

.cardContainer .card{
    min-height:100px;
    border-radius:16px;
    width:200px;
    box-shadow:1px 1px 10px 0px rgb(244, 244, 247);
}
@media screen and (max-width:700px){
    
.cardContainer{
flex-flow:row wrap !important;
}

.cardContainer .card{
    width:48% !important;
    box-shadow:1px 1px 10px 0px rgb(244, 244, 247);
}

.cardContainer .card *{
    font-size:smaller;
}

.cardContainer .card{
    margin:1% !important;
}

}

@media screen and (max-width:1000px){
    
    .cardContainer{
    flex-flow:row wrap !important;
    }
    
    .cardContainer .card{
        width:31% !important;
        box-shadow:1px 1px 10px 0px rgb(244, 244, 247);
    }
    
    .cardContainer .card{
        margin:1% !important;
    }
    
    }
.cardContainer .card:not(.cardContainer .card:nth-child(1)){
    margin-left:10px;
}

.Activities_container{
    background:rgb(243, 243, 245);
    padding:5px;
    max-width:90%;
    border-radius:10px;
}
.activitiesContainer{
    padding:10px;

}

@media screen and (max-width:700px){
.Activities_container{
min-width:100%;
max-width:100%;
}
.Activities_container .Activities_card{
    min-width:100%;
    max-width:100%;
}
.activitiesContainer{
    padding:0px;
    padding-top:20px;
    padding-bottom:17px;
}
.dashboard{
    padding:3px;
}

}
.Activities_container .Activities_card{
    box-shadow:1px 1px 10px 0px rgb(243, 243, 245);
background: white;
border-radius:10px;
padding:5px;
    }


    .Activities_container .Activities_card:not(.Activities_container .Activities_card:nth-child(1)){
        margin-top:5px;

    }

