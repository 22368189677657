#PaymentForm{
    margin:0 auto;
    width:100%;
    max-width:max-content;
    box-shadow:1px 1px 10px 0px lightgrey;
    padding:10px;
    background:white;
    border-radius:20px;
    margin-top:20px;
}
.paymentContainer{
    width:100%;
}
@media screen and (min-width:900px){
    #PaymentForm{
        display:flex;
        gap:10px;
        align-items:center;
    }
}
   
.paymentContainer{
    padding:16px 25px;
    min-height:90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    }
@media screen and (max-width:900px){
    
.paymentContainer{
padding:16px 25px;
min-height:100vh;
display: flex;
align-items: center;
justify-content: center;
}
    #PaymentForm {
        padding-top:25px;
        padding-bottom:25px;
    }
    
    #PaymentForm .inputHolder{
        margin-top:10px;
        padding-top:20px;
    }
}