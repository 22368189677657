.verifyUI{
    width:100%;
    min-height:100vh;
}
.verifyUI .box{
    min-width:300px;
    min-height:300px;
    background:white;
    border-radius:15px !important;
    position:relative;

}
.verifyUI .box button{
    width:100%;
}

.verifyUI .loader{
position:absolute;
width:100%;
height:100%;
top:0;
left:0;
}
.result{
    width:100%;
    height:100%;
    padding:30px;
    text-align:center;
}
.result img{
    width:200px;
    height:200px;
    border-radius:50%;
}





.videoContainer{
position: relative;
overflow:hidden;
height:330px;


}

.videoContainer .line{
    width:100%;
    height: 20px;
    background:linear-gradient(rgba(80, 28, 110, 0.116),rgba(80, 28, 110, 0.233),rgba(92, 0, 145, 0.87)) ;
    border-bottom:1px solid rgb(34, 0, 54);
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name:line;
    position: absolute;
    top:0;
    lefT:0;

}
@keyframes line {
    0%{
        margin-top:-10%;
    }
    100%{
        margin-top:110%;
    }
}