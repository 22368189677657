.sidebar{
  background:white;
  min-height:100vh;
  overflow:auto;
  
  z-index:99;
  padding:20px 10px !important;
  }
  

  @media screen and (max-width:700px){
  .appContainer .sidebar{
  display:none !important; 
  }
  
  .userNav{
  padding:20px;
  padding-top:5px !important;
  padding-bottom:2px !important;
position:fixed;
min-width:100vw !important;
  }

  .userNavPlaceholder{
    display:block !important;
  }
  .greetingUser{
  text-align:start;
  }
  }
  .greetingUser{
      color:dimgrey;
  }
  .greetingUser span{
  opacity: 0.7;
  }
  .sidebar button {
      border:none !important;
      width:100%;
      text-align:start;
      outline:none !important;
      box-shadow:none !important;
      margin-top:10px;
  }
.sidebar button .icon{
      color:var(--color);
      padding-right:10px;
  }
  
  .appContainer{
      background:var(--white);
      padding:0px !important;
  }
  .userNav{
      width:100%;
      min-height:70px;
      box-shadow:1px 1px 10px 0px rgb(244, 244, 247);
      background:white;
      padding:10px;
      padding-right:20px;
      z-index:9;
    padding:16px;
      margin:0 !important;
  }
  
  .userNavPlaceholder{
    display:none;
    width:100%;
    min-height:70px;
  }

  .sidebarMobileContent{
width:100%;
height:100vh;
background:rgba(21, 11, 32, 0.226);
position:fixed;
z-index:10;
overflow:auto;
  }
  .sidebarMobileContent .content{
background:white;
min-height:100%;
width:60%;
animation-name:openMenu;
animation-duration:800ms;
  }
  @keyframes openMenu{
0%{
  transform:translate(-200vw,0);
}
100%{
  transform:translate(-0vw,0);
}
  }

  
  @keyframes closeMenu{
    0%{
      transform:translate(0vw,0);
    }
    100%{
      transform:translate(-200vw,0);
    }
      }

*[class*=primary]{
  background: #07662f !important;
}
*[class*=tertiary]{
  color: #07662f !important;
}
